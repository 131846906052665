import React, {useEffect} from 'react';
import './home.css'
import Rings from './Rings.jpg'
// import Holiday from './Winter-celebration.jpg'


const Home = () => {

    // useEffect(() => {
    //     const letters = document.querySelectorAll('.letter');
    
    //     let index = 0;
    //     const interval = setInterval(() => {
    //       letters.forEach((letter, i) => {
    //         letter.className = `letter letter-${(i + index) % 2}`;
    //       });
    //       index++;
    //     }, 1000);
    
    //     return () => clearInterval(interval); 
    //   }, []);

    return (
            <div className='card-1'>
            <h1>Welcome</h1>
            <div className='welcome-p'>
                <p>
                Founded in 1874, the Chicago Jewelers' Association (CJA) is the Midwest's oldest and most prestigious jewelry industry group. 
                The CJA provides a forum for retailers, wholesalers, sales representatives, and manufacturers to exchange vital information and new ideas. This networking opportunity has been found to be invaluable by some of the industry's most successful professionals.
                </p>

                {/* <div className='holiday-display'>
                    <p className='rsvp'>
                    {Array.from("Click Image To RSVP!").map((char, index) => (
                        <span key={index} className={`letter letter-${index % 2}`}>{char}</span>
                    ))}</p>
                <a href='https://partiful.com/e/QOmoc7yHUYS97CoburUc' target='_blank' rel='noopener noreferrer'>
                <img src={Holiday} alt=" " className='holiday'/>
                </a>
                </div> */}    

                <div className="holiday-link-container">
                    <a 
                      href="https://www.dropbox.com/scl/fo/d3tr4mgjklngyy18ae9w9/AC0swsXhLrqVqjyUBeueHk0?rlkey=pdffhwkq67dm41v1m5snu8npa&e=3&st=ha4y5xqe&dl=0"
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="holiday-link">
                      150th Anniversary Party Photos Click To View!
                    </a>
                </div>

                <div className='ring-display'>
                <img src={Rings} alt=" " className='ring-image'/>
                </div>
            </div>
        </div>
    )

}

export default Home;